import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"

import {Layout} from "../components/Layout";

export default function MtbWassen() {
    return (
        <Layout restrictedWidth={false}
                pageTitle={"Jouw MTB wassen? Bekijk onze tips!✓ | Steven's Bike Service"}
                metaDescription={"Wil jij jouw MTB wassen maar weet je niet goed hoe? Bekijk dan ons stappenplan! Wij zijn dé MTB specialist van regio Deventer!✓"}>

            <div className={"fixed top-20 z-0 p-0"}>
                <StaticImage className={"landscape:w-screen portrait:h-screen"}
                             src={"../images/sbs-mtb-werkplaats.jpg"} alt={"Lekker ragguh"}/>
            </div>
            <div className={"h-[65vh]"}></div>

            <div className={"bg-white z-20 w-full"}>
                <div className=" max-w-6xl mx-auto lg:content-between gap-y-6">
                    <h2 className="text-4xl font-extrabold mt-16">Mountainbike wassen</h2>
                    <p className={"leading-relaxed md:leading-relaxed lg:leading-relaxed mt-5 px-2 col-span-2 text:l md:text-xl lg:text-xl py-3"}>
                        Om te zorgen dat je met je mountainbike over de routes kan blijven knallen, zul je er toch ook
                        regelmatig een lappie overheen moeten halen. Voor het wassen van de mtb adviseer ik de spullen
                        van Muc-off. Het is veilig voor je bike en milieu omdat Muc-off niet alleen jouw fiets maar ook
                        het milieu hoog in het vaandel heeft staan.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Wanneer jouw mountainbike wassen?</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Bij droog weer is het niet nodig om de MTB elke keer te wassen maar kun je best een of twee keer
                        overslaan. Bij regen of veel modder is ‘t het beste is om gelijk na het fietsen je mountainbike
                        schoon te maken.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het vuil en de modder komen dan veel makkelijker los, dan wanneer je het laat opdrogen. Doe dit
                        met zo min mogelijk water op de plekken waar lagers zitten: denk dan aan je naven, balhoofd en
                        trapas. Water zorgt er namelijk voor dat lagers gaan roesten en de levensduur aanzienlijk
                        verminderd wordt.
                    </p>
                    <h3 className="text-2xl font-extrabold mt-3">Stappenplan voor het wassen van jouw mountainbike:</h3>
                    <ol className="space-y-1 list-decimal list-outside ml-8">
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed pt-3"}>
                            Spuit de MTB af met een tuinslang, of met een speciale hogedrukspuit voor de fiets zoals die
                            van <a
                            href={"https://eu.muc-off.com/collections/bicycle-pressure-washer-bundles/products/pressure-washer-bicycle-bundle"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>Muc-off</a>, maar ontzie zo veel mogelijk je lagers, fork, demper en
                            dropper.
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed "}>
                            Spuit de MTB daarna in met <a
                            href={"https://eu.muc-off.com/products/nano-tech-bike-cleaner/"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>Muc-off Nano Tech Bike Cleaner</a> en laat dit drie tot vijf minuten
                            intrekken. Doe dit niet in de volle zon omdat dit vlekken kan veroorzaken.
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            3. Nadat je in die 5 minuten al je pr’s heb gecheckt op Strava kun je de mountainbike met
                            een <a
                            href={"https://eu.muc-off.com/collections/brushes-cloths-sponges/products/soft-washing-brush"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>zachte wasborstel</a> wassen. Gebruikt voor je naven een
                            speciale <a
                            href={"https://eu.muc-off.com/collections/brushes-cloths-sponges/products/wheel-component-brush"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>naafborstel</a> zodat je makkelijk tussen de spaken door je naven kunt
                            poetsen. Gebruik voor je aandrijving een <a
                            href={"https://eu.muc-off.com/collections/brushes-cloths-sponges/products/claw-brush"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>claw brush</a>, mocht het nodig zijn.
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            Als je al het vuil los hebt geborsteld kun je de fiets afspuiten. Ook hier weer opletten
                            voor de lagers, fork, demper en dropper!
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            En nu het belangrijkste van alles: je bike weer goed droog maken. Hoe beter je dit doet, hoe
                            minder je last zult hebben van roest en oxidatie waardoor alles beter en langer mee gaat.
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            Als je alles droog hebt, zijn de remschijven en remblokken aan de beurt. Wrijf de schijven
                            en blokken schoon met een schone doek tot er geen zwart meer vanaf komt. <br/>De doek moet
                            écht
                            schoon zijn, anders maak je de schijf alleen maar viezer. Hierdoor gaan de remmen slechter
                            werken en piepen. Door de remmen opnieuw <a
                            href={"/mtb-remmen-onderhoud"}
                            className={"text-blue-600 hover:underline"}
                            target={"_self"}>in te remmen</a> heb je bij je volgende rit de beste
                            remmen!
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            <a href={"/mtb-ketting-onderhoud"}
                               className={"text-blue-600 hover:underline"}
                               target={"_self"}>Smeer</a> je ketting opnieuw in met 2 laagjes Squirt.
                        </li>
                        <li className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed"}>
                            Behandel je fork en eventueel je demper en dropper met bijvoorbeeld <a
                            href={"https://eu.muc-off.com/products/silicon-shine-500ml"}
                            className={"text-blue-600 hover:underline"}
                            target={"_blank"}>Muc-Off Silicon Shine</a> zodat deze ultra soepel blijft veren en je
                            stiction voorkomt. Pas op dat je met een spray
                            niet in de buurt van je remmen komt. <br/>Beter is om het op een doek te spuiten, uit de
                            buurt
                            van je fiets, en het met de doek op je binnenpoten van de fork, demper en dropper aan te
                            brengen.
                        </li>
                    </ol>
                    <div className={"pb-10 col-span-2 mt-6"}>
                        <div
                            className="p-4 w-full text-center bg-white rounded-lg border shadow-md sm:p-8 bg-gray-200 text-black">

                            <p className={"px-2 italic col-span-2 text:xl md:text-2xl lg:text-2xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-5"}>
                                Neem nu contact met mij op en ontdek wat ik voor jouw mountainbike kan betekenen. Tot snel!</p>

                            <a href={"/contact"}>
                                <button type="button"
                                        className={"md:w-1/2 text:xl lg:text-2xl text-black bg-white hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-base px-auto p-5 text-center"}>
                                    Neem nu contact op!
                                </button>
                            </a>
                        </div>
                    </div>
                    <h3 className="text-2xl font-extrabold mt-3">Jouw mountainbike in topconditie door dit handige
                        stappenplan!</h3>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Als je deze stappen volgt blijft jouw bike in topconditie en zul je er meer plezier van hebben.
                        Je zou het frame en aandrijving zo nu en dan kunnen behandelen met <a
                        href={"https://eu.muc-off.com/products/bike-protect-500ml"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Muc-off bike protect</a>. Dit
                        verdrijft water en en maakt het vuil afstotend.
                    </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Het is niet na elke wasbeurt nodig maar eens per 2 maanden is wel aan te bevelen. Ook hier
                        weer, let op dat je geen spray op je remmen krijgt. Spuit het op een doek uit de buurt van
                        je fiets en breng het met de doek aan op het frame en de aandrijving inclusief je
                        derailleur(s). </p>
                    <p className={"px-2 col-span-2 text:l md:text-xl lg:text-xl leading-relaxed md:leading-relaxed lg:leading-relaxed py-3"}>
                        Alles om je fiets te poetsen en te laten shinen is bij mij te verkrijgen en je kunt zelfs je
                        lege fles <a
                        href={"https://eu.muc-off.com/products/nano-tech-bike-cleaner"}
                        className={"text-blue-600 hover:underline"}
                        target={"_blank"}>Muc-off Nano Tech Bike Cleaner</a> bij mij bijvullen. Hierdoor bespaar je
                        niet alleen op de prijs maar spaar je ook het milieu!
                    </p>
                </div>
            </div>
        </Layout>
    )
}
